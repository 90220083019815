
import React from "react"
import PostmortemformEditor from "../../components/negosyonow/controls/postmortemeditor";

const PostmortemformPage = ({location}) => {

	return <PostmortemformEditor
				location={location}
				mode={0}
			/>

}

export default PostmortemformPage;
